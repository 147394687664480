import React from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Layout from '../components/Layout';
import StyledHero from '../components/StyledHero/StyledHero';
import Gallery from '../components/Gallery/Gallery';

const PhotoGallery = ({data}) => { 
    const { heroImage, galleries } = data;
    return (
        <Layout title="Gallery">
            <StyledHero image={heroImage.childImageSharp.fluid}>
                <h1 style={{textTransform:'uppercase'}}>Jaisalmer Desert Safari <span className="text-primary">Album</span></h1>
                <h4>Desert Safari - Jaisalmer Tours</h4> 
            </StyledHero>
            <Container>
                <Gallery galleries={ galleries }/>
            </Container>
        </Layout>
    );
};
export const query = graphql `
    query {
        heroImage:file(relativePath:{eq:"Heros/india-2825918_1920.jpg"}){
            id
            childImageSharp{
                fluid(maxWidth:1920, maxHeight:800){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        galleries:allWordpressWpGallery {
            edges {
                node {
                    title
                    slug
                    mainGallery {
                        full_image_url {
                            localFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
export default PhotoGallery;
