import React, { Fragment, useState } from 'react';
import Image from 'gatsby-image';
import Button from 'react-bootstrap/Button';
import Carousel, { Modal, ModalGateway } from 'react-images'; 
import styles from './gallery.module.css'; 

const Gallery = ({galleries}) => {
    const [filter, setFilter] = useState(galleries.edges[0].node.slug); 
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const toggleLightbox = (selectedIndex) => {
        setIsLightboxOpen(prevState => !prevState);
        setSelectedIndex(selectedIndex);
    }
    const filterHandler = (filter) => { 
        setFilter(filter);
        setIsLightboxOpen(false);
        setSelectedIndex(0);
    } 
    
    return (
        <div className={styles.Gallery}>
             <ul className={styles.GalleryFilterList}>
                 {
                     galleries.edges.map(({node}) => (
                     <li key={node.id} className={styles.GalleryFilterItem}>
                         <Button 
                            variant="outline-primary" 
                            size="sm" 
                            onClick={ () => filterHandler(node.slug) }
                            className={`rounded-0 ${filter === node.slug ? 'active' : ''}`}>{node.title}</Button> 
                     </li>))
                 }
             </ul> 
                {
                    galleries.edges.map(({node}) => {
                        if(node.slug !== filter){
                            return '';
                        }
                        const lightBoxImages = node.mainGallery.map(({full_image_url}) => {
                            return {
                                source: full_image_url.localFile.childImageSharp.fluid.srcWebp
                            }
                        })
                        return (
                            <Fragment key={`gWrap_${node.id}`}> 
                                <div className={styles.GalleryGrid}>
                                {
                                    !isLightboxOpen &&
                                    node.mainGallery.map(({full_image_url:{localFile:{childImageSharp}}}, index) => (
                                            <div 
                                                className={styles.GalleryGridItem} 
                                                onClick = {() => toggleLightbox(index)}
                                                key={childImageSharp.id}>
                                                <Image 
                                                    className={styles.GalleryImage} 
                                                    objectFit="cover"
                                                    objectPosition="50% 50%"
                                                    fluid={childImageSharp.fluid}  
                                                    alt={node.title} />
                                            </div> 
                                    ))
                                }  
                                </div>
                                <ModalGateway>
                                    {isLightboxOpen ? (
                                        <Modal onClose={toggleLightbox}>
                                        <Carousel 
                                            currentIndex={selectedIndex} 
                                            frameProps={{ autoSize: 'height' }}
                                            views={lightBoxImages}
                                        />
                                        </Modal>
                                    ) : null}
                                </ModalGateway> 
                            </Fragment>
                        );
                       
                    })
                } 
        </div>
    );
};

export default Gallery;
